/* eslint-disable no-alert */
import { NotificationManager } from 'components/common/react-notifications';

export const ToastMessage = (type, className, message, title) => {
  const cName = className || '';
  switch (type) {
    case 'primary':
      NotificationManager.primary(message, title, 3000, null, null, cName);
      break;
    case 'secondary':
      NotificationManager.secondary(message, title, 3000, null, null, cName);
      break;
    case 'info':
      NotificationManager.info('Info message', '', 3000, null, null, cName);
      break;
    case 'success':
      NotificationManager.success(message, title, 3000, null, null, cName);
      break;
    case 'warning':
      NotificationManager.warning(message, title, 3000, null, null, cName);
      break;
    case 'error':
      NotificationManager.error(
        message,
        title,
        5000,
        () => {
          alert('callback');
        },
        null,
        cName
      );
      break;
    default:
      NotificationManager.info('Info message');
      break;
  }
};

export const PrimaryToastMessage = (message, title, className) => {
  const cName = className || '';
  const msg = message || '';
  const tit = title || '';
  NotificationManager.primary(msg, tit, 3000, null, null, cName);
};

export const ErrorToastMessage = (title, message, className) => {
  const cName = className || '';
  const msg = message || '';
  const tit = title || '';
  NotificationManager.error(msg, tit, 3000, null, null, cName);
};
export const SuccessToastMessage = (title, message, className) => {
  const cName = className || '';
  const msg = message || '';
  const tit = title || '';
  NotificationManager.success(msg, tit, 3000, null, null, cName);
};

export default ToastMessage;
