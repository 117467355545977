import axios from 'axios';
import { ErrorToastMessage } from 'containers/ui/ToastMessage';
import { getCurrentUser } from 'helpers/Utils';
import moment from 'moment'

// const sign = require('jwt-encode');
console.log(process.env.REACT_APP_BACKEND_URL)

// BACKEND URL
export const tradingAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
})

tradingAPI.interceptors.request.use(
  function (config) {
    const tempConfig = config
    const userData = getCurrentUser()
    if(userData?.uid) {
      tempConfig.headers.Authorization = `Bearer ${userData?.uid}`
    }
    return tempConfig
  },
  function (error) {
    return Promise.reject(error)
  }
)

tradingAPI.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    console.log('Error: ', error)
    if (error?.response?.status === 401) {
      // localStorage.removeItem('userData')
      // localStorage.setItem('ErrorMessage', "You have been logged out due to token expired, Please log back in")
      console.log('************** Othrizr API 401 Error ***************')
      if (error.response.data.errors) {
        console.log('************** Othrizr API error.Description ***************')
        console.log(error.response.data.errors.join(', '))
      } else {
        console.log(error.response)
      }
    } else if (error.response.status === 400) {
      console.log('************** Othrizr API 401 Error ***************')
      if (error.response.data.errors) {
        console.log('************** Othrizr API error.Description ***************')
        console.log(error.response.data.errors)
      }
      else if (error.response.data.Description) {
        console.log('************** Othrizr API error.Description ***************')
        console.log(error.response.data.Description)
      } else {
        console.log(error.response)
      }
    } else if (error.response.data.Description) {
      console.log('************** Othrizr API error.Description ***************')
      console.log(error.response.data.Description)
      ErrorToastMessage("Error", error.response.data.Description)
    } else if (error.response.data.errors) {
      console.log('************** Othrizr API error.Description ***************')
      console.log(error.response.data.errors)
      ErrorToastMessage(error.response.data.errors.join(","))
    } else if (error.message) {
      console.log('************** Othrizr API error.Description ***************')
      console.log(error.message)
    } else {
      console.log('************** Othrizr API Error ***************')
      console.log(JSON.stringify(error))
    }
  }
)

export default async function handler(req, res) {
  res.end()
}
